import React from 'react'
import './feature.less'
import { Img } from '@tueri/react'
import { Link } from 'gatsby'

export default function Feature({ title, subTitle, image, children, swap, learnMore, boxShadow }) {

    return (
        <div className={`feature__container${ swap ? ' feature__container--swap' : '' }`}>
            <div className='feature__textBlock'>
                <h2 className='feature__subTitle'>
                    { subTitle }
                </h2>
                <h3 className='feature__title'>
                    { title }
                </h3>
                {
                    learnMore ? <Link className='secondaryButton' to={`/features/${learnMore}`}>Learn more</Link> : null
                }                
            </div>
            <div className={`feature__content${ boxShadow ? ' feature__content--boxShadow' : '' }`}>
                { typeof image === 'string' ? <Img src={ image } alt={ subTitle } /> : image}
            </div>
            <div className='feature__body'>
                { children }
            </div>
        </div>
    )

}