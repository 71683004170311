import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import Feature from '../../components/Feature'
// import { graphql } from 'gatsby'
// import ImageCompare from '../../components/Compare'
import Helmet from 'react-helmet'

const meta = {
  title: 'Image managment features',
  image: '274877906965',
  path: '/features/',
  description: 'Blazing fast websites with automatic image optimization, responsive images, image lazy-loading and low-quality image placeholders.',
  keywords: 'responsive images, image optimization, image lazy loading, low quality image placeholders, image blur up, image placeholder, react image optimization'
}

const Features = ({ data }) => (
    <Layout>
      <Helmet
            html={[
              { lang: 'en', prefix: 'og: http://ogp.me/ns#' }
            ]}
            meta={[
              { property: 'og:title', content: meta.title },
              { property: 'twitter:title', content: meta.title },
              { property: 'og:type', content: 'website' },
              { property: 'og:image', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'twitter:image:src', content: 'https://cdn.tueri.io/' + meta.image + '/' + encodeURIComponent(meta.title) + '.jpg?w=1200&height=1200' },
              { property: 'og:url', content: 'https://tueri.io' + meta.path },
              { property: 'og:description', content: meta.description },
              { property: 'twitter:description', content: meta.description },
              { name: 'description', content: meta.description },
              { name: 'keywords', content: meta.keywords },
              { name: 'author', content: 'Dane Stevens' },
              { property: 'twitter:card', content: 'summary_large_image' }
            ]}
          >
            <title>Tueri - { meta.title }</title>
          </Helmet>
        <Hero
            large
            title={<span>Frictionless experiences for your customers,<br/>massive time-savings for your developers</span>}
            subTitle={`Insanely fast image delivery with automatic resizing, compression and deferred loading.`}
        />

        <Feature
            title='Automatically compressed images delivered in the best possible format'
            subTitle='Image optimization'
            learnMore='image-optimization'
            image='274877906961'
            boxShadow
        />

        <Feature
            title='Perfectly sized images for every device'
            subTitle='Responsive images'
            learnMore='responsive-images'
            image='274877906960'
            swap
        >
          {/* Your users are using literally thousands of combinations of devices, form-factors, and resolutions. 
          We automatically detect your user's device size and resolution and deliver a precisely sized image at the fastest speeds possible without sacrificing image clarity. */}
        </Feature>

        <Feature
          title={`Get a speed boost by deferring loading images outside the viewport`}
          subTitle='Lazy-loading images'
          learnMore='lazy-loading-images'
          image='274877906965'
        />

        <Feature
          // title='Decreased time to first meaningful paint with low-res images loaded first'
          title='Progressively load images with low-res image placeholders'
          subTitle='Progressive loading images'
          image='274877906967'
          learnMore='progressive-loading-images'
          swap
        />

        <Feature 
          title='Stream remotely hosted images through our image pipeline'
          subTitle='Remote image sources'
          image='274877907175'
          learnMore='remote-image-sources'
          boxShadow
        />


    </Layout>
)

export default Features

// export const query = graphql`
//   query {
//     developer: file(relativePath: { eq: "perfectlySizedImages.png" }) {
//       childImageSharp {
//         fixed(quality: 100, width:800) {
//             ...GatsbyImageSharpFixed
//         }
//       }
//     }

//     marketer: file(relativePath: { eq: "instagram.png" }) {
//       childImageSharp {
//         fluid {
//             ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `